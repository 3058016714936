

























import Vue from 'vue'
import {
  ArticleListData,
  PractitionerListData,
  ReviewListData,
  Locations,
  PageData
} from 'HealthAdvisorBackendApp'
import { GenericIndexList, ReviewItem } from 'HealthAdvisorFrontendApp'
import { API_PAGE_HOME_ID } from '@/utils/settings'
import { mapApiUsersToIndexItems } from '@/utils/api/practitioners'
import { mapApiReviewDataToReviewSlider } from '@/utils/api/review'
import { routes } from '@/utils/routes'
import { makeFlatArrayOfLocations } from '@/utils/api/locations'
import { mapApiArticles } from '@/utils/api/articles'
import NavbarHeader from '@/components/shared/NavbarHeader/index.vue'
import HeroSearch from '@/components/home/HeroSearch/index.vue'
import ValuePoints from '@/components/home/ValuePoints/index.vue'
import CarouselReviews from '@/components/home/CarouselReviews/index.vue'
import TopArticles from '@/components/home/TopArticles/index.vue'
import TopPractitioners from '@/components/home/TopPractitioners/index.vue'
import SearchBy, {
  SearchByItem,
  SearchByLists
} from '@/components/home/SearchBy/index.vue'
import { apiFetcherPromise } from '@/utils/api/fetcher'

export default Vue.extend({
  components: {
    NavbarHeader,
    HeroSearch,
    ValuePoints,
    CarouselReviews,
    TopArticles,
    TopPractitioners,
    SearchBy
  },
  async asyncData({ $axios, query }) {
    const start = new Date().getTime()
    const allApiData = await Promise.all([
      apiFetcherPromise<PageData>({
        $axios,
        apiUrl: `/page/${API_PAGE_HOME_ID}/`
      }),
      apiFetcherPromise<ArticleListData>({
        $axios,
        apiUrl: `/articles/?limit=5`
      }),
      apiFetcherPromise<PractitionerListData>({
        $axios,
        apiUrl: `/users/?get_by=top&limit=8`
      }),
      apiFetcherPromise<ReviewListData>({
        $axios,
        apiUrl: `/reviews/?limit=10`
      }),
      apiFetcherPromise<Locations>({
        $axios,
        apiUrl: `/locations/?get_by=top`
      })
    ])
    const [
      fetchedHome,
      fetchedArticles,
      fetchedUsers,
      fetchedReviews,
      fetchedLocations
    ] = allApiData
    const end = new Date().getTime()

    const articles = mapApiArticles(fetchedArticles.responseData)
    const practitioners: GenericIndexList = mapApiUsersToIndexItems(
      fetchedUsers.responseData
    )
    const reviews: ReviewItem[] = mapApiReviewDataToReviewSlider(
      fetchedReviews.responseData
    )

    // search by TOP LOCATIONS
    const topLocations = fetchedLocations.responseData
      ? makeFlatArrayOfLocations(fetchedLocations.responseData)
      : []

    const searchByCityItems: SearchByItem[] = topLocations.map(
      ({ label, slug }) => ({
        label,
        url: routes.locationPlace.makeUrl(slug)
      })
    )

    const searchByLists: SearchByLists = [
      {
        name: 'City',
        url: routes.locations.makeUrl(),
        items: searchByCityItems
      },
      {
        name: 'Expertise',
        url: '#',
        items: []
      }
    ]

    return {
      head: fetchedHome.head,
      schemaJsonLD: fetchedHome.schemaJsonLD,
      articles,
      practitioners,
      reviews,
      searchByLists,
      perf: end - start,
      headerNoWave: Boolean(query.headerNoWave)
    }
  },
  computed: {
    searchByListsHasContent() {
      const searchByLists: SearchByLists = this.$data.searchByLists
      const notEmptyList = searchByLists.filter(
        ({ items }) => items && items.length
      )
      return Boolean(notEmptyList && notEmptyList.length)
    }
  },
  mounted() {
    console.info('perf', this.$data.perf)
  },
  jsonld() {
    return this.$data.schemaJsonLD || null
  },
  head() {
    return this.$data.head
      ? {
          ...this.$data.head
        }
      : {}
  }
})
