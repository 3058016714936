import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { SeoProps } from 'HealthAdvisorBackendApp'
import { makeHeadFieldsFromApi, NuxtSeoHead, SchemaJsonLD } from './seo'

// unfortunately at the time of writing nuxt doens't expose NuxtError type
type NuxtError = any

interface FetcherParams {
  $axios: NuxtAxiosInstance
  apiUrl: string
  error?: (error: NuxtError) => void
  cancelToken?: any
}

type Fetcher = <ResponseType extends {}>(
  params: FetcherParams
) => Promise<{
  head?: NuxtSeoHead
  schemaJsonLD?: SchemaJsonLD
  responseData: ResponseType
}>

// Here is our basic fetcher to be used pages
// this will check if content can be fetched,
// will return seo content if any and redirect to 404 is data fetching fails
// if you don't want 404 redirection (example in secondary data fetching), don't pass nuxt `error` method
export const apiFetcher: Fetcher = async ({
  $axios,
  error,
  apiUrl,
  cancelToken
}) => {
  try {
    const fetchedData = await $axios.$get(apiUrl, { cancelToken })
    if (error && (!fetchedData || fetchedData.error || !fetchedData.data)) {
      error({ statusCode: 404, message: 'Page not found' })
    }
    const apiData = fetchedData.data
    const seoData: SeoProps = apiData && apiData.seo

    return {
      head: seoData ? makeHeadFieldsFromApi(seoData) : undefined,
      schemaJsonLD: seoData && seoData.schema ? seoData.schema : undefined,
      responseData: fetchedData.data
    }
  } catch {
    if (error) error({ statusCode: 404, message: 'Page not found' })

    // returning empty object to make it TS complaints
    // we don't need extra guards in nuxt pages, since error
    // method will redirect user to 404
    return {
      head: undefined,
      schemaJsonLD: undefined,
      responseData: {}
    }
  }
}

export const apiFetcherPromise: Fetcher = ({
  $axios,
  error,
  apiUrl,
  cancelToken
}) => {
  return $axios
    .$get(apiUrl, { cancelToken })
    .catch(() => {
      if (error) error({ statusCode: 404, message: 'Page not found' })
      return {
        head: undefined,
        schemaJsonLD: undefined,
        responseData: {}
      }
    })
    .then((fetchedData) => {
      if (error && (!fetchedData || fetchedData.error || !fetchedData.data)) {
        error({ statusCode: 404, message: 'Page not found' })
      }
      const apiData = fetchedData.data
      const seoData: SeoProps = apiData && apiData.seo

      return {
        head: seoData ? makeHeadFieldsFromApi(seoData) : undefined,
        schemaJsonLD: seoData && seoData.schema ? seoData.schema : undefined,
        responseData: fetchedData.data
      }
    })
}
