






































































import Vue, { PropOptions } from 'vue'
import { ReviewItem } from 'HealthAdvisorFrontendApp'
import Avatar from '@/components/shared/Avatar/index.vue'
import ArrowRight from '~/static/icons/arrow-right.svg?inline'
import Star from '~/static/icons/star.svg?inline'

export default Vue.extend({
  name: 'CarouselReviews',
  components: {
    Avatar,
    ArrowRight,
    Star
  },
  props: {
    reviews: {
      type: Array,
      required: true
    } as PropOptions<Array<ReviewItem>>
  },
  data() {
    return {
      loading: true,
      swiperOption: {
        navigation: {
          nextEl: '.btn-carousel-next',
          prevEl: '.btn-carousel-prev',
          disabledClass: 'btn-carousel-disabled'
        },
        slidesPerView: 2.5,
        spaceBetween: 30,
        breakpoints: {
          // below 992
          992: {
            slidesPerView: 2.1,
            spaceBetween: 0
          },
          // below 768
          768: {
            slidesPerView: 1.2,
            spaceBetween: 0
          }
        }
      }
    }
  },
  methods: {
    onReady() {
      this.loading = false
    }
  }
})
