



















import Vue from 'vue'

export default Vue.extend({
  name: 'SwitchToggleLabels',
  functional: true,
  props: {
    options: {
      type: Array,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    onToggle: {
      type: Function,
      required: true
    },
    variant: {
      type: String as () => 'success' | 'white' | 'secondary',
      default: 'success'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
