

















import Vue from 'vue'

export default Vue.extend({
  name: 'CardArticle',
  functional: true,
  props: {
    category: {
      type: String,
      default: undefined,
      required: true
    },
    title: {
      type: String,
      default: undefined,
      required: true
    },
    intro: {
      type: String,
      default: ''
    },
    extraCssClass: {
      type: String,
      default: undefined
    }
  }
})
