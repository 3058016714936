

















































import Vue, { PropOptions } from 'vue'
import SwitchToggle from '@/components/shared/SwitchToggleLabels/index.vue'

type OnChangeCallback = (newIndex: number) => void
export type SearchByItem = { label: string; url: string }

export type SearchByLists = {
  name: string
  url: string
  items: SearchByItem[]
}[]

export default Vue.extend({
  name: 'SearchBy',
  components: {
    SwitchToggle
  },
  props: {
    lists: {
      type: Array,
      required: true
    } as PropOptions<SearchByLists>
  },
  data() {
    return {
      activeListIndex: 0
    }
  },
  methods: {
    onToggle(index: number) {
      // toggling only if list contains items
      if (
        this.lists[index] &&
        this.lists[index].items &&
        this.lists[index].items.length
      ) {
        this.activeListIndex = index
      }
    }
  }
})
