






































import Vue, { PropOptions } from 'vue'
import { GenericIndexList } from 'HealthAdvisorFrontendApp'
import Avatar from '@/components/shared/Avatar/index.vue'
import MarkerIcon from '~/static/icons/marker.svg?inline'

export default Vue.extend({
  name: 'SliderPractitioner',
  components: {
    Avatar,
    MarkerIcon
  },
  props: {
    showSlider: {
      type: Boolean,
      required: true
    },
    practitioners: {
      type: Array,
      required: true
    } as PropOptions<Array<GenericIndexList>>
  },
  data() {
    return {
      swiperDestroyed: false,
      swiperOption: {
        slidesPerView: 2.5,
        slidesPerColumn: 1,
        loop: true,
        spaceBetween: 20,
        draggable: true,
        centeredSlides: true,

        breakpoints: {
          // below 992
          992: {
            slidesPerView: 2.6,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      }
    }
  },
  mounted() {
    if (this.showSlider === false) {
      const swiperpractitioners = (this as any).swiperpractitioners
      swiperpractitioners.destroy()
    }
  }
})
