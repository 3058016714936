
















































































import Vue from 'vue'
import WaveTop from '~/static/waves/wave-home-values-top.svg?inline'
import WaveBottom from '~/static/waves/wave-home-values-bottom.svg?inline'

interface ValuePoint {
  imageName: string
  title: string
  description: string
}

export default Vue.extend({
  name: 'ValuePoints',
  components: {
    WaveTop,
    WaveBottom
  }
})
