import { SeoProps } from 'HealthAdvisorBackendApp'

export interface NuxtSeoHead {
  title?: string
  meta?: {
    hid: string
    name: string
    content: string
  }[]
}

export type SchemaJsonLD = { [key: string]: unknown }

export const makeHeadFieldsFromApi = (seoData: SeoProps): NuxtSeoHead => {
  if (!seoData) {
    return {}
  }

  const ogImages = Object.keys(seoData.open_graph_images || {}).length
    ? Object.keys(seoData.open_graph_images).map((key) => ({
        url: seoData.open_graph_images[key].url,
        alt: seoData.open_graph_images[key].alt,
        type: seoData.open_graph_images[key].type
      }))
    : []

  const title = seoData.title || ''
  const description = seoData.open_graph_description || ''
  const ogType = seoData.open_graph_type || 'website'
  const ogSiteName = seoData.open_graph_site_name || ''
  const image = ogImages && ogImages.length ? ogImages[0].url : ''

  return {
    title,
    meta: [
      // general
      { hid: 'description', name: 'description', content: description },

      // facebook
      {
        hid: 'og:title',
        name: 'og:title',
        content: title
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: description
      },
      {
        hid: 'og:type',
        name: 'og:type',
        content: ogType
      },
      {
        hid: 'og:image',
        name: 'og:image',
        content: image
      },
      {
        hid: 'og:site_name',
        name: 'og:site_name',
        content: ogSiteName
      },

      // twitter
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: title
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: description
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: image
      }
    ]
  }
}

export const makeSeoDescription = (str: string) =>
  !str ? '' : str.replace(/<[^>]*>?/gm, '').slice(0, 180) + '...'
