




































import Vue, { PropOptions } from 'vue'
import { GenericIndexList } from 'HealthAdvisorFrontendApp'
import SliderPractitioner from './SliderPractitioner.vue'
import SectionTitle from '@/components/home/SectionTitle/index.vue'
import WaveTop from '~/static/waves/wave-home-practitioners-top.svg?inline'
import WaveBottom from '~/static/waves/wave-home-practitioners-bottom.svg?inline'
import { routes } from '@/utils/routes'

export default Vue.extend({
  name: 'PractitionersGrid',
  components: {
    SectionTitle,
    WaveTop,
    WaveBottom,
    SliderPractitioner
  },
  props: {
    practitioners: {
      type: Array,
      required: true
    } as PropOptions<GenericIndexList>
  },
  data() {
    return {
      routes,
      showSlider: true,
      renderKey: ''
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      if (window.innerWidth > 768) {
        this.showSlider = false
        this.renderKey = 'without-slider'
      }
      if (window.innerWidth < 768) {
        this.showSlider = true
        this.renderKey = 'with-slider'
      }
    }
  }
})
