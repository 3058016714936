





























import Vue, { PropOptions } from 'vue'
import { CardArticleProps } from 'HealthAdvisorFrontendApp'
import CardArticle from '@/components/shared/CardArticle/index.vue'

export default Vue.extend({
  components: {
    CardArticle
  },
  props: {
    showSlider: {
      type: Boolean,
      required: true
    },
    articles: {
      type: Array,
      required: true
    } as PropOptions<Array<CardArticleProps>>
  },
  data() {
    return {
      swiperDestroyed: false,
      swiperOption: {
        navigation: {
          nextEl: '.btn-carousel-next',
          prevEl: '.btn-carousel-prev',
          disabledClass: 'btn-carousel-disabled'
        },
        slidesPerView: 1.8,
        slidesPerColumn: 1,
        loop: true,
        spaceBetween: 10,
        draggable: true,
        centeredSlides: true
      }
    }
  },
  mounted() {
    if (this.showSlider === false) {
      const swiperArticles = (this as any).swiperArticles
      swiperArticles.destroy()
    }
  }
})
