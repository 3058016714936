import {
  ArticleCategoryListData,
  ArticleListData
} from 'HealthAdvisorBackendApp'
import {
  ArticleCategories,
  CardMediaArticleProps
} from 'HealthAdvisorFrontendApp'
import NoPicPlaceholder from '@/static/images/no-pic-placeholder.jpg'
import { routes } from '~/utils/routes'

/*
 * This function receives data from api reponse and returns
 * an array of categories with articles and consumable in front end
 */
export const mapApiArticlesCategories = (
  responseData: ArticleCategoryListData,
  hideEmpty?: boolean
): ArticleCategories => {
  if (!responseData) {
    return []
  }

  return Object.keys(responseData)
    .map((key) => ({
      id: responseData[key][0].term_id,
      name: responseData[key][0].name,
      slug: responseData[key][0].slug,
      count: responseData[key][0].count
    }))
    .filter((cat) => (hideEmpty ? cat.count > 0 : true))
}

/*
 * Return category data (ArticleCategory) from a category ID
 */
export const findCategoryFromId = (
  categoryId: number,
  categories: ArticleCategories
) => categories.find((cat) => cat.id === categoryId)

/*
 * This function map api fetched data in order to return an array of articles
 * consumable in front end
 */
export const mapApiArticles = (
  responseData: ArticleListData
): CardMediaArticleProps[] => {
  const { items } = responseData
  if (!items) {
    return []
  }

  return Object.keys(items).map((articleKey) => {
    const articleItem = items[articleKey]
    const categoryName =
      articleItem.categories && articleItem.categories.length
        ? articleItem.categories[0].name
        : ''
    const categorySlug =
      articleItem.categories && articleItem.categories.length
        ? articleItem.categories[0].slug
        : ''

    const imageUrl = (items[articleKey].data?.gallery || [])[0]

    return {
      category: categoryName,
      categoryUrl: routes.articlesCategory.makeUrl(categorySlug),
      title: articleItem.post_title,
      intro: articleItem.post_excerpt,
      imageUrl: imageUrl || NoPicPlaceholder,
      articleUrl: routes.article.makeUrl(categorySlug, articleItem.post_name),
      authorName: articleItem.author?.full_name,
      date: articleItem.post_date
    }
  })
}
