




































import Vue from 'vue'
import { routes } from '@/utils/routes'
import SearchBox from '@/components/shared/SearchBox/index.vue'

type UserLocation = {
  lat: number
  lng: number
}

export default Vue.extend({
  name: 'HeroSearch',
  components: {
    SearchBox
  },
  props: {
    heroTitle: {
      type: String,
      default: 'Hero title'
    },
    placeholder: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      isSettingUserPosition: false,
      userGeolocationIsBlockedMessage: undefined as string | undefined
      // useUserLocation: null as null | UserLocation
    }
  },
  methods: {
    setUserLocation() {
      if (navigator.geolocation) {
        this.$data.isSettingUserPosition = true

        navigator.geolocation.getCurrentPosition(
          (position) => {
            // this.useUserLocation = {
            //   lat: position.coords.latitude,
            //   lng: position.coords.longitude
            // }

            if (position.coords.latitude && position.coords.longitude) {
              this.$router.push({
                path: routes.search.makeUrl(),
                query: {
                  location: 'Your Location',
                  mid_lat: `${position.coords.latitude}`,
                  mid_lng: `${position.coords.longitude}`
                }
              })
            }
          },
          () => {
            this.userGeolocationIsBlockedMessage =
              'Please check your settings. You have disabled gelocation for this website.'
            this.$data.isSettingUserPosition = false
          }
        )
      }
    }
    // onSubmit(payload: SearchedPlace) {
    //   this.useUserLocation = {
    //     lat: payload.lat,
    //     lng: payload.lng
    //   }
    // }
  }
})
