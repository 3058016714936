import { PractitionerListData, ClinicListData } from 'HealthAdvisorBackendApp'
import {
  MarkerWithData,
  CardResult,
  CardMediaProps,
  GenericIndexList
} from 'HealthAdvisorFrontendApp'
import { routes } from '../routes'
import NoPicPlaceholder from '@/static/images/no-pic-placeholder.jpg'
import GocLogo from '~/static/images/GOC-logo.jpg'

/*
 * This function map api fetched data in order to return an array
 * of Markers consumable in front end
 */
export const mapApiPractitionersToMarkers = (
  responseData: PractitionerListData
): MarkerWithData[] => {
  const { items } = responseData

  if (!items) {
    return []
  }

  return Object.keys(items).map((clinicKey) => {
    const practitionerItem = items[clinicKey]
    const distance = parseFloat(
      `${practitionerItem.listing?.data?.distance || 0}`
    )
    const url = routes.practitioner.makeUrl(
      practitionerItem.user_nicename,
      practitionerItem.ID
    )

    return {
      name: practitionerItem.full_name,
      image: practitionerItem.profile_picture || NoPicPlaceholder,
      rating: practitionerItem.rating?.ratingValue || 0,
      address: practitionerItem.listing?.data?.full_address || '',
      telephone: practitionerItem.phone?.formatted || '',
      position: {
        lat: parseFloat(practitionerItem.listing?.data?.latitude || '0'),
        lng: parseFloat(practitionerItem.listing?.data?.longitude || '0')
      },
      distance: `${distance} mile${distance > 0 ? 's' : ''} away` || '',
      url,
      isClinic: false
    }
  })
}

/*
 * This function map api fetched data in order to return an array
 * of CardResults consumable in front end
 *  used in search page
 */
export const mapApiPractitionersToCardResults = (
  responseData: PractitionerListData
): CardResult[] => {
  const { items } = responseData

  if (!items) {
    return []
  }

  return Object.keys(items).map((clinicKey) => {
    const practitionerItem = items[clinicKey]
    const distance = parseFloat(
      `${practitionerItem.listing?.data?.distance || 0}`
    )
    const url = routes.practitioner.makeUrl(
      practitionerItem.user_nicename,
      practitionerItem.ID
    )

    return {
      name: practitionerItem.full_name,
      rating: practitionerItem.rating?.ratingValue || 0,
      image: practitionerItem.profile_picture || NoPicPlaceholder,
      distance: `${distance} mile${distance > 0 ? 's' : ''} away` || '',
      address: practitionerItem.listing?.data?.full_address || '',
      telephone: practitionerItem.phone?.raw || '',
      telephoneFormatted: practitionerItem.phone?.formatted || '',
      isAvailableToday: false,
      averagePrice: 0,
      url,
      isClinic: false
    }
  })
}

// map api practitioner list data to practitioner index page items
export const mapApiUsersToIndexItems = (
  responseData: PractitionerListData
): GenericIndexList => {
  const { items } = responseData

  if (!items || !Object.keys(items).length) {
    return []
  }

  const practitioners: GenericIndexList = Object.keys(items).map((itemKey) => {
    const practitioner = items[itemKey]
    return {
      imageUrl: practitioner.profile_picture || NoPicPlaceholder,
      name: practitioner.full_name,
      location: practitioner.location ? practitioner.location[0] : '',
      url: routes.practitioner.makeUrl(
        practitioner.user_nicename,
        practitioner.ID
      )
    }
  })
  return practitioners
}

// to add more certification, pass data and add a `CardMediaProps` item to the returned array
export const mapAccreditationData = ({
  goc_registration_number
}: {
  goc_registration_number?: string
}) => {
  const gocAccreditation: CardMediaProps | undefined = goc_registration_number
    ? {
        imageUrl: GocLogo,
        title: 'General Osteopathic Council (GOC)',
        description: `Registered: ${goc_registration_number}`
      }
    : undefined

  // const hcpcAccreditation:
  //   | CardMediaProps
  //   | undefined = hcpc_hpc_registration_number
  //   ? {
  //       imageUrl: HcpcLogo,
  //       title: 'Health and Care Professions Council (HCPC)',
  //       description: `Registered: ${hcpc_hpc_registration_number}`
  //     }
  //   : undefined

  return [gocAccreditation].filter((a) => !!a)
}

// give fetched clinic list form api, returns an array of CardMediaProps consumable by Media component
export const mapWorkingPlaces = (clinicList: ClinicListData) => {
  if (!clinicList || !clinicList.items) {
    return []
  }
  const clinicItems = clinicList.items
  const itemKeys = Object.keys(clinicItems)

  if (!itemKeys || !itemKeys.length) {
    return []
  }

  const workingPlaces: CardMediaProps[] = itemKeys.map((clinic) => {
    const clinicItem = clinicItems[clinic]
    const address = `${clinicItem.data?.full_address}, ${clinicItem.data
      ?.postcode || ''}`
    const clinicLogo: string = clinicItem.data?.logo || NoPicPlaceholder

    return {
      imageUrl: clinicLogo,
      title: clinicItem.post_title || '',
      description: address,
      url: routes.clinic.makeUrl(clinicItem.post_name)
    }
  })

  return workingPlaces
}
