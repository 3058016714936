



















import Vue, { PropOptions } from 'vue'

export default Vue.extend({
  name: 'Avatar',
  functional: true,
  props: {
    avatarUrl: {
      type: String,
      default: undefined,
      required: true
    },
    altText: {
      type: String,
      default: undefined,
      required: true
    },
    size: {
      type: String,
      default: '50px'
    },
    height: {
      type: String,
      default: undefined
    },
    squared: {
      type: Boolean,
      default: false
    },
    imageFit: {
      type: String,
      default: 'cover'
    } as PropOptions<'cover' | 'contain'>
  }
})
