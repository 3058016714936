



































import Vue, { PropOptions } from 'vue'
import { CardArticleProps } from 'HealthAdvisorFrontendApp'
import SliderArticles from './SliderArticles.vue'
import SectionTitle from '@/components/home/SectionTitle/index.vue'
import { routes } from '@/utils/routes'

export default Vue.extend({
  name: 'TopArticles',
  components: {
    SliderArticles,
    SectionTitle
  },
  props: {
    articles: {
      type: Array,
      required: true
    } as PropOptions<Array<CardArticleProps>>
  },
  data() {
    return {
      routes,
      showSlider: true,
      renderKey: ''
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      if (window.innerWidth > 980) {
        this.showSlider = false
        this.renderKey = 'without-slider'
      }
      if (window.innerWidth < 980) {
        this.showSlider = true
        this.renderKey = 'with-slider'
      }
    }
  }
})
