import { ReviewListData } from 'HealthAdvisorBackendApp'
import { ReviewCardProp, ReviewItem } from 'HealthAdvisorFrontendApp'
import { format } from 'date-fns'

export const mapApiReviewDataToReviewList = (
  reviews: ReviewListData
): ReviewCardProp[] => {
  if (!reviews || !reviews.items) {
    return []
  }

  const reviewItems = reviews.items
  const reviewsList: ReviewCardProp[] = Object.keys(reviewItems).map((key) => {
    const item = reviewItems[key]
    const date = item.post_date ? new Date(item.post_date) : null

    return {
      rating: item.data?.review_rating?.ratingValue || 1,
      date: date ? format(date, 'do, MMMM yyyy') : 'N/A',
      authorName: item.author_name || '',
      authorLocality: undefined,
      message: item.post_content || '',
      isVerified: true // all users are verified :)
    }
  })
  return reviewsList
}

export const mapApiReviewDataToReviewSlider = (
  reviews: ReviewListData
): ReviewItem[] => {
  if (!reviews || !reviews.items) {
    return []
  }

  const reviewItems = reviews.items || []

  const reviewsList: ReviewItem[] = Object.keys(reviewItems).map(
    (reviewKey) => {
      return {
        name: reviewItems[reviewKey].author_name || '',
        avatarUrl: reviewItems[reviewKey].author_picture || '',
        text: reviewItems[reviewKey].post_content || '',
        rating: reviewItems[reviewKey].data?.review_rating?.ratingValue || 5
      } as ReviewItem
    }
  )

  return reviewsList
}
