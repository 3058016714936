import { LocationState } from 'HealthAdvisorFrontendApp'
import { ClinicListData } from 'HealthAdvisorBackendApp'

type FlattenLocations = {
  id: number
  label: string
  slug: string
  region: string
}[]

export const makeFlatArrayOfLocations = (
  storedLocations: LocationState
): FlattenLocations => {
  const regionsArray = Object.keys(storedLocations)
  if (!regionsArray || !regionsArray.length) {
    return []
  }

  return regionsArray.reduce((topLocationsAccumulator, currentRegion) => {
    // guard
    if (!Object.keys(storedLocations[currentRegion]).length) {
      return topLocationsAccumulator
    }

    const locationsInCurrentRegion = Object.keys(
      storedLocations[currentRegion]
    ).reduce((regionLocationAcc, currentTownKey) => {
      return [
        ...regionLocationAcc,
        {
          id: storedLocations[currentRegion][currentTownKey].ID,
          label: storedLocations[currentRegion][currentTownKey].label,
          slug: storedLocations[currentRegion][currentTownKey].slug,
          region: currentRegion
        }
      ]
    }, [] as FlattenLocations)

    return [...topLocationsAccumulator, ...locationsInCurrentRegion]
  }, [] as FlattenLocations)
}

export const filterClinicsNotInTown = (
  clinics: ClinicListData,
  townName: string
): ClinicListData => {
  const { items } = clinics
  const itemsKeys = Object.keys(items)
  if (!items || !itemsKeys.length) {
    return clinics
  }

  const cleanedItems: ClinicListData['items'] = itemsKeys.reduce(
    (newItemsData, clinicId) => {
      const clinicIsInTown = items[clinicId].data?.location?.length
        ? items[clinicId].data!.location!.includes(townName)
        : null

      return clinicIsInTown
        ? {
            ...newItemsData,
            [clinicId]: items[clinicId]
          }
        : newItemsData
    },
    {} as ClinicListData['items']
  )

  return {
    ...clinics,
    items: cleanedItems
  }
}
